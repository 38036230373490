import Immutable from "seamless-immutable";
import MerchantActionTypes from "./actionTypes";

const initialState = Immutable({
  logo: "",
  name: "",
  name_tc: "",
  favicon: "",
  web_name: "",
  desc: "",
  desc_tc: "",
});

const MerchantReducer = (state = initialState, action) => {
  switch (action.type) {
    case MerchantActionTypes.GET_ONE:
      return { ...state, ...action.data };
    default:
      return state;
  }
};

export default MerchantReducer;
