import MerchantActionTypes from "./actionTypes";
import BaseActions, { APIRequestGenerator } from "../../base/BaseActions";
import APIHelper from "../../base/APIHelper";

const RESOURCE = "merchant";
const MERCHANT = process.env.REACT_APP_MERCHANT;

function getOne(token, params) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/api/${RESOURCE}/${MERCHANT}`,
      params,
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );

      const {
        logo,
        company_name,
        company_name_tc,
        Favicon,
        web_name,
        desc,
        desc_tc,
        map_icon,
        full_map_icon,
      } = response || {};
      dispatch({
        type: MerchantActionTypes.GET_ONE,
        data: {
          logo,
          name: company_name,
          name_tc: company_name_tc,
          favicon: Favicon,
          web_name,
          desc,
          desc_tc,
          map_icon,
          full_map_icon,
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

const MerchantActions = {
  ...BaseActions(RESOURCE, MerchantActionTypes),
  getOne,
};
export default MerchantActions;
