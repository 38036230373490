import ActionTypes from "./actionTypes";
import BaseActions, { APIRequestGenerator } from "../../base/BaseActions";
import APIHelper from "../../base/APIHelper";

const RESOURCE = "transactions";

function update(id, token, data) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/api/${RESOURCE}/update/transaction/${id}?access_token=${token}`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );
      dispatch({
        type: ActionTypes.UPDATE,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function scanReceipt(ocrToken, data) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `https://ocr-api.neuralsense.io/ocr?access_token=${ocrToken}`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );
      dispatch({
        type: ActionTypes.SCAN_RECEIPT,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

const Actions = {
  update,
  scanReceipt,
  ...BaseActions(RESOURCE, ActionTypes),
};
export default Actions;
