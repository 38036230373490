import BaseActionTypes from "../../base/BaseActionTypes";

const RESOURCE = "Aggregator";

const AggregatorActionTypes = {
  ...BaseActionTypes(RESOURCE),
  CHECK_USER: `${RESOURCE}/CHECK_USER`,
};

export default AggregatorActionTypes;
