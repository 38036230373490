import React from "react";
import { NavLink } from "react-router-dom";
import { LOGIN, REGISTRATION } from "settings/constant";
import { useTranslation } from "react-i18next";
import LoginLogo from "../../../assets/images/login.png";
import ProfileLogo from "../../../assets/images/register.png";

const AuthMenu = ({ showRegister }) => {
  const { t } = useTranslation();

  return (
    <div className="avatar-dropdown">
      <NavLink className="signin" to={LOGIN}>
        <img src={LoginLogo} />
        {t("signIn")}
      </NavLink>

      {showRegister && (
        <NavLink className="signup" to={REGISTRATION}>
          <img src={ProfileLogo} />
          {t("signUp")}
        </NavLink>
      )}
    </div>
  );
};

export default AuthMenu;
