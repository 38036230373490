import BaseActionTypes from "../../base/BaseActionTypes";

const RESOURCE = "Site";

const SiteActionTypes = {
  ...BaseActionTypes(RESOURCE),
  GET_ONE: `${RESOURCE}/GET_ONE`,
  GET_FAQ: `${RESOURCE}/GET_FAQ`,
};

export default SiteActionTypes;
