import BaseActionTypes from "../../base/BaseActionTypes";

const RESOURCE = "ServiceContent";

const ServiceContentActionTypes = {
  ...BaseActionTypes(RESOURCE),
  GET_LIST: `${RESOURCE}/GET_LIST`,
};

export default ServiceContentActionTypes;
