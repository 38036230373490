import BaseActionTypes from "../../base/BaseActionTypes";

const RESOURCE = "App";

const AppActionTypes = {
  ...BaseActionTypes(RESOURCE),
  SET_FOOTERHEIGHT: `${RESOURCE}/SET_FOOTERHEIGHT`,
  SET_HEADERHEIGHT: `${RESOURCE}/SET_HEADERHEIGHT`,
};

export default AppActionTypes;
