import React from "react";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { load, save } from "redux-localstorage-simple";
import reducer from "./reducers";

const enhancer = compose(applyMiddleware(thunk, save()));

export default function configureStore() {
  return createStore(reducer, load(), enhancer);
}
