import BaseActionTypes from "../../base/BaseActionTypes";

const RESOURCE = "Carparks";

const CarparksActionTypes = {
  ...BaseActionTypes(RESOURCE),
  GET_ONE: `${RESOURCE}/GET_ONE`,
  TOGGLE_SHOW_MAP: `${RESOURCE}/TOGGLE_SHOW_MAP`,
};

export default CarparksActionTypes;
