import BaseActionTypes from "../../base/BaseActionTypes";

const RESOURCE = "transactions";

const ActionTypes = {
  UPDATE: `${RESOURCE}/UPDATE`,
  SCAN_RECEIPT: `${RESOURCE}/SCAN_RECEIPT`,
  ...BaseActionTypes(RESOURCE),
};

export default ActionTypes;
