import Immutable from "seamless-immutable";
import OCRActionTypes from "./actionTypes";

const defaultValues = {
  step: 1,
  tnc: false,
  hkidImage: "",
  addressProofImage: "",
  hkidNameEn: "",
  hkidNameTc: "",
  isHkidMatched: false,
  isAddressMatched: false,
  addressProofType: null,
  hkidErrorMessage: "",
  addressProofErrorMessage: "",
};

const initialState = Immutable({
  ...defaultValues,
});

const OCRReducer = (state = initialState, action) => {
  switch (action.type) {
    case OCRActionTypes.UPDATE:
      return { ...state, ...action.data };
    case OCRActionTypes.RESET:
      return defaultValues;
    default:
      return state;
  }
};

export default OCRReducer;
