import React from "react";
import styled from "styled-components";

const LogoImg = styled.img`
  width: ${({ width }) => (width ? `${width}px` : "190px")};
`;

const Logo = ({ logo, width, maxWidth }) => (
  <>{logo && <LogoImg src={logo} width={width} maxWidth={maxWidth} />}</>
);

export default Logo;
