import UsersActionTypes from "./actionTypes";
import BaseActions, { APIRequestGenerator } from "../../base/BaseActions";
import APIHelper from "../../base/APIHelper";

const RESOURCE = "users";
const MERCHANT = process.env.REACT_APP_MERCHANT;

function getMe(token) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/api/${RESOURCE}/me`,
      { mid: MERCHANT },
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );
      const account = response.accounts.find((ac) => ac._id === MERCHANT);

      dispatch({
        type: UsersActionTypes.GET_ME,
        data: {
          avatar: account ? account?.images?.[0] : "",
          firstName: account
            ? account?.first_name
            : response?.general?.first_name,
          lastName: account ? account?.last_name : response?.general?.last_name,
          isMigratedToTPM: account?.isMigratedToTPM || false,
        },
      });

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function updateUser(id, data, token) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/api/${RESOURCE}/update/user/${id}`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        { ...data, mid: MERCHANT },
        true,
        headers
      );
      dispatch({
        type: UsersActionTypes.UPDATE_USER,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function updateOctCard(data, token) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/api/${RESOURCE}/update/oct_card?mid=${MERCHANT}`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );
      dispatch({
        type: UsersActionTypes.UPDATE_OCTOPUS,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function updateVehicle(data, token) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/api/${RESOURCE}/update/vehicle?mid=${MERCHANT}`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );
      dispatch({
        type: UsersActionTypes.UPDATE_VEHICLE,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function updatePayment(data, token) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/api/${RESOURCE}/update/payment?mid=${MERCHANT}`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );
      dispatch({
        type: UsersActionTypes.UPDATE_PAYMENT,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function contactUs(data) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/api/${RESOURCE}/contactus?mid=${MERCHANT}`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );
      dispatch({
        type: UsersActionTypes.CONTACT_US,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function getMeByPhone(phone) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/api/${RESOURCE}/admin/checkbyphone`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        { mid: MERCHANT, phone },
        true,
        headers
      );
      dispatch({
        type: UsersActionTypes.GET_ME_BY_PHONE,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function checkUserIsMigratedToTPM(data) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/api/${RESOURCE}/check/migrated/${MERCHANT}`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );
      dispatch({
        type: UsersActionTypes.CHECK_MIGRATED,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

const UsersActions = {
  ...BaseActions(RESOURCE, UsersActionTypes),
  getMe,
  updateUser,
  updateVehicle,
  updateOctCard,
  updatePayment,
  contactUs,
  getMeByPhone,
  checkUserIsMigratedToTPM,
};
export default UsersActions;
