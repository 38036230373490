import React, { useEffect, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { Layout as LayoutWrapper } from "antd";
import useWindowSize from "library/hooks/useWindowSize";
import LayoutProvider from "context/LayoutProvider";
import {
  ACCOUNT_SETTINGS,
  ACTIVATE_WITH_MOBILE,
  ACTIVATION_FORM,
  ACTIVATE_REGISTRATION,
  CAR_PARK_LIST,
  CAR_PARK_DETAIL,
  CHECK_MAILBOX,
  CONFIRM_CHANGE_PASSWORD,
  CONFIRM_REGISTRATION,
  CORPORATE_CONFIRM_REGISTRATION,
  FORGET_PASSWORD,
  IMAGE_EDIT_PAGE,
  INDIVIDUAL_FORM,
  INVOICE,
  LOGIN,
  MOBILE_LOGIN,
  PRIVACY_POLICY,
  PROFILE,
  REGISTER_CORPORATE_FORM,
  REGISTER_INDIVIDUAL_FORM,
  REGISTRATION,
  RESET_PASSWORD,
  VEHICLE_EDIT_PAGE,
  OCR_VERIFICATION,
  ONE_TIME_PAYMENT,
  PAYMENT,
} from "settings/constant";
import { useSelector } from "react-redux";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import actions, { useActions } from "../../redux/actions";
import PaymentNoticeBar from "./PaymentNoticeBar";

const { Content } = LayoutWrapper;

export default withRouter(({ children, location }) => {
  const { width } = useWindowSize();
  const singlePageUrlFromConst = CAR_PARK_DETAIL.split("/");
  const singlePageUrlFormLocation = location.pathname.split("/");
  const { landing: { footer: { displaymain: showFooter } = {} } = {} } =
    useSelector((state) => state.site);

  const CarparksActions = useActions(actions.CarparksActions);

  const showPaymentNoticeBar = useMemo(
    () =>
      location.pathname === ONE_TIME_PAYMENT || location.pathname === PAYMENT,
    [location.pathname]
  );

  useEffect(() => {
    if (
      !location.pathname.includes(CAR_PARK_LIST) &&
      !location.pathname.includes(CAR_PARK_DETAIL)
    ) {
      CarparksActions.toggleShowMap(false);
      localStorage.setItem("showMap", false);
    }
  }, [window.location.pathname]);

  return (
    <LayoutProvider>
      {/* {location.pathname === LOGIN || */}
      {/* location.pathname === FORGET_PASSWORD || */}
      {/* location.pathname === ACTIVATE_WITH_MOBILE || */}
      {/* location.pathname === ACTIVATION_FORM || */}
      {/* location.pathname === ACTIVATE_REGISTRATION || */}
      {/* location.pathname === REGISTER_CORPORATE_FORM || */}
      {/* location.pathname === REGISTRATION || */}
      {/* location.pathname === REGISTER_INDIVIDUAL_FORM || */}
      {/* location.pathname === REGISTER_CORPORATE_FORM || */}
      {/* location.pathname === INDIVIDUAL_FORM || */}
      {/* location.pathname === CONFIRM_REGISTRATION || */}
      {/* location.pathname === CORPORATE_CONFIRM_REGISTRATION || */}
      {/* location.pathname === CHECK_MAILBOX || */}
      {/* location.pathname === RESET_PASSWORD || */}
      {/* location.pathname === CONFIRM_CHANGE_PASSWORD || */}
      {/* location.pathname === MOBILE_LOGIN ? ( */}
      {/*  <> */}
      {/*    <Header /> */}
      {/*    <Content>{children}</Content> */}
      {/*    <Footer resized={getFooterSize} /> */}
      {/*  </> */}
      {/* ) : ( */}
      <>
        <Header />
        {showPaymentNoticeBar && <PaymentNoticeBar />}
        <Content>{children}</Content>
        {location.pathname === `${PROFILE + INVOICE}` ||
        location.pathname === PROFILE ||
        location.pathname === `${ACCOUNT_SETTINGS + IMAGE_EDIT_PAGE}` ||
        location.pathname === `${ACCOUNT_SETTINGS + VEHICLE_EDIT_PAGE}` ||
        location.pathname === ACCOUNT_SETTINGS ? (
          <div style={{ height: "33px" }} />
        ) : (
          <>
            {showFooter && <Footer />}
            {singlePageUrlFormLocation[1] === singlePageUrlFromConst[1] && (
              <>{width < 1200 && <div style={{ height: "74px" }} />}</>
            )}
          </>
        )}
      </>
      {/* )} */}
    </LayoutProvider>
  );
});
