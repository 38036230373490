import BaseActionTypes from "../../base/BaseActionTypes";

const RESOURCE = "News";

const NewsActionTypes = {
  ...BaseActionTypes(RESOURCE),
  GET_LIST: `${RESOURCE}/GET_LIST`,
  GET_DETAIL: `${RESOURCE}/GET_DETAIL`,
};

export default NewsActionTypes;
