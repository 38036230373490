const ErrorHandler = (error) => {
  const errMsg =
    error?.error?.message ||
    error?.error?.msg ||
    error?.message ||
    error?.msg ||
    "Unknown Error. Please try again.";
  if (["cancelled", "已取消", "Failed to fetch"].indexOf(errMsg) === -1)
    window.alert(errMsg);
  console.log(error);
};

export const UncaughtRejectionHandler = (event) => {
  ErrorHandler(event.reason);
};

export default ErrorHandler;
