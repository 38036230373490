import BaseActionTypes from "../../base/BaseActionTypes";

const RESOURCE = "Users";

const UsersActionTypes = {
  ...BaseActionTypes(RESOURCE),
  GET_ME: `${RESOURCE}/GET_ME`,
  UPDATE_VEHICLE: `${RESOURCE}/UPDATE_VEHICLE`,
  UPDATE_OCTOPUS: `${RESOURCE}/UPDATE_OCTOPUS`,
  UPDATE_PAYMENT: `${RESOURCE}/UPDATE_PAYMENT`,
  UPDATE_USER: `${RESOURCE}/UPDATE_USER`,
  CONTACT_US: `${RESOURCE}/CONTACT_US`,
  GET_ME_BY_PHONE: `${RESOURCE}/GET_ME_BY_PHONE`,
  CHECK_MIGRATED: `${RESOURCE}/CHECK_MIGRATED`,
};

export default UsersActionTypes;
