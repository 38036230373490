import BaseActionTypes from "../../base/BaseActionTypes";

const RESOURCE = "Parking";

const ParkingActionTypes = {
  ...BaseActionTypes(RESOURCE),
  GET_FILTER_DATA: `${RESOURCE}/GET_FILTER_DATA`,
  GET_LIST: `${RESOURCE}/GET_LIST`,
};

export default ParkingActionTypes;
