import React from "react";
import PropTypes from "prop-types";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import { FaGlobeAsia } from "react-icons/fa";
import { LangSwitcher } from "../../container/Layout/Header/Header.style";
import NavbarWrapper, {
  Container,
  LogoArea,
  MenuArea,
  AvatarWrapper,
  AuthWrapper,
  MenuWrapper,
} from "./Navbar.style";
import Language from "../../assets/images/language.png";

const cookies = new Cookies();

const Navbar = ({
  className,
  logo,
  avatar,
  navMenu,
  authMenu,
  profileMenu,
  isLogin,
  // headerType,
  searchComponent,
  location,
  searchVisibility,
}) => {
  const addAllClasses = ["navbar"];
  if (className) {
    addAllClasses.push(className);
  }
  // if (headerType) {
  //   addAllClasses.push(`is_${headerType}`);
  // }
  const { i18n } = useTranslation();

  return (
    <NavbarWrapper
      className={addAllClasses.join(" ")}
      style={{ flexDirection: "column" }}
    >
      <Container>
        {logo || searchVisibility ? <LogoArea href="/">{logo}</LogoArea> : null}
        <MenuArea>
          {navMenu && (
            <MenuWrapper className="main_menu">{navMenu}</MenuWrapper>
          )}
          {isLogin && avatar ? (
            <AvatarWrapper>{profileMenu}</AvatarWrapper>
          ) : (
            authMenu && (
              <AuthWrapper className="auth_menu">{authMenu}</AuthWrapper>
            )
          )}
          <LangSwitcher
            onClick={() => {
              const lang = i18n.language === "zh" ? "en" : "zh";
              i18next.changeLanguage(lang);
              cookies.set("lang", lang);
            }}
          >
            <img src={Language} alt="" />
            {i18n.language === "zh" ? " EN " : " 繁體 "}
          </LangSwitcher>
        </MenuArea>
      </Container>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  className: PropTypes.string,
  navMenu: PropTypes.element,
  avatar: PropTypes.element,
  authMenu: PropTypes.element,
  isLogin: PropTypes.bool,
  headerType: PropTypes.oneOf(["transparent", "default"]),
};

export default Navbar;
