import BaseActionTypes from "../../base/BaseActionTypes";

const RESOURCE = "Upload";

const UploadActionTypes = {
  ...BaseActionTypes(RESOURCE),
  UPLOAD_IMAGE: `${RESOURCE}/UPLOAD_IMAGE`,
};

export default UploadActionTypes;
