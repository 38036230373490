import PromotionActionTypes from "./actionTypes";
import BaseActions, { APIRequestGenerator } from "../../base/BaseActions";
import APIHelper from "../../base/APIHelper";

const RESOURCE = "promotion";
const MERCHANT = process.env.REACT_APP_MERCHANT;

function checkDiscount(data, token) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/api/${RESOURCE}/check?mid=${MERCHANT}`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );
      dispatch({
        type: PromotionActionTypes.CHECK_DISCOUNT,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

const PromotionActions = {
  ...BaseActions(RESOURCE, PromotionActionTypes),
  checkDiscount,
};
export default PromotionActions;
