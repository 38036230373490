import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

const NavbarWrapper = styled.nav`
  /* display: flex;
  width: 100%;
  padding: 0.5rem 1rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${themeGet("border.3", "#E6E6E6")};
  background-color: ${themeGet("color.1", "#ffffff")}; */

  flex-flow: row nowrap;
  justify-content: flex-start;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 1rem;
  text-align: center;
  color: #2c3e50;
  background-color: #fff;
`;

export const Container = styled.div`
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;

  @media only screen and (min-width: 991px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const LogoArea = styled.a`
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
`;

export const MenuArea = styled.div`
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  align-items: center;
`;

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  .avatar-dropdown {
    display: block;
    color: rgba(0, 0, 0, 0.5);
    padding: 0.5rem 0.5rem;

    .avatar-a {
      color: rgb(245, 2, 8);
      margin-right: 0px;
      &:hover {
        text-decoration: underline;
      }
      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 3px;
        margin-left: 5px;
      }
    }

    .logoutLogo {
      width: 15px;
      margin-right: 3px;
    }

    a {
      background-color: transparent;
      border: none;
      cursor: pointer;
      padding: 0;
      margin-right: 15px;
      color: rgba(0, 0, 0, 0.5);
    }

    .dropdown-handler {
      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 3px;
        margin-left: 5px;
      }
    }

    .dropdown-menu {
      min-width: 180px;
      border-right: 0;
      position: absolute;
      right: 0;
      top: 45px;
      border-radius: 4px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      transition: all 0.3s ease;

      &.hide {
        opacity: 0;
        visibility: hidden;
      }
      &.active {
        opacity: 1;
        visibility: visible;
      }
      li {
        color: ${themeGet("text.0", "#2C2C2C")};
        font-size: 15px;
        line-height: 18px;
        font-weight: 400;
        height: auto;
        padding: 0;
        transition: color 0.2s ease-in-out;
        &.ant-menu-item-selected,
        &.ant-menu-item-active {
          background-color: transparent;
        }
        a {
          display: block;
          padding: 8px 16px;
          color: ${themeGet("text.0", "#2C2C2C")};
          transition: color 0.2s ease-in-out;
          &:hover {
            color: ${themeGet("primary.0", "#008489")};
          }
          &.active {
            font-weight: 700;
            color: ${themeGet("primary.0", "#008489")};
          }
        }
        button {
          padding: 0;
          border: 0;
          cursor: pointer;
          padding: 8px 16px;
          width: 100%;
          text-align: left;
          background-color: transparent;
          transition: color 0.2s ease-in-out;
          &:hover {
            color: ${themeGet("primary.0", "#008489")};
          }
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
`;

export const MenuWrapper = styled.ul`
  flex-direction: row;
  margin-left: auto !important;
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  touch-action: manipulation;

  & > a {
    color: rgb(128, 128, 128);
    padding: 0.5rem 0.5rem;
    text-decoration: none;
    background-color: transparent;
    font-size: 1rem;
    display: flex;
    align-items: center;

    &:hover {
      text-decoration: underline;
    }

    > a {
      color: rgb(128, 128, 128);
    }
  }
`;

export const AuthWrapper = styled.div`
  @media only screen and (max-width: 1200px) {
    margin-left: 15px;
  }

  .avatar-dropdown {
    display: flex;
    padding: 0.5rem 0.5rem;
    align-items: center;

    .signin {
      display: flex;
      align-items: center;
      color: rgb(128, 128, 128);
      margin-right: 15px;
      &:hover {
        text-decoration: underline;
      }

      img {
        vertical-align: middle;
        width: 15px;
        margin-right: 3px;
      }
    }

    .signup {
      display: flex;
      align-items: center;
      margin-right: 15px;
      &:hover {
        color: #f50208;
        text-decoration: underline;
      }

      img {
        vertical-align: middle;
        width: 15px;
        margin-right: 3px;
      }
    }

    .dropdown-menu {
      min-width: 180px;
      border-right: 0;
      position: absolute;
      right: 0;
      top: 45px;
      border-radius: 4px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      transition: all 0.3s ease;
      &.hide {
        opacity: 0;
        visibility: hidden;
      }
      &.active {
        opacity: 1;
        visibility: visible;
        z-index: 9999;
      }
      li {
        color: ${themeGet("text.0", "#2C2C2C")};
        font-size: 15px;
        line-height: 18px;
        font-weight: 400;
        height: auto;
        padding: 0;
        transition: color 0.2s ease-in-out;
        &.ant-menu-item-selected,
        &.ant-menu-item-active {
          background-color: transparent;
        }
        a {
          display: block;
          padding: 8px 16px;
          color: ${themeGet("text.0", "#2C2C2C")};
          transition: color 0.2s ease-in-out;
          &:hover {
            color: ${themeGet("primary.0", "#008489")};
          }
          &.active {
            font-weight: 700;
            color: ${themeGet("primary.0", "#008489")};
          }
        }
        button {
          border: 0;
          cursor: pointer;
          padding: 8px 16px;
          width: 100%;
          text-align: left;
          background-color: transparent;
          transition: color 0.2s ease-in-out;
          &:hover {
            color: ${themeGet("primary.0", "#008489")};
          }
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
`;

export default NavbarWrapper;
