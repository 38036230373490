import BaseActionTypes from "../../base/BaseActionTypes";

const RESOURCE = "Bookings";

const BookingsActionTypes = {
  ...BaseActionTypes(RESOURCE),
  CREATE_BOOKING: `${RESOURCE}/CREATE_BOOKING`,
  HANDLE_3DS: `${RESOURCE}/HANDLE_3DS`,
};

export default BookingsActionTypes;
