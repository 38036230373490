import AwardActionTypes from "./actionTypes";
import BaseActions, { APIRequestGenerator } from "../../base/BaseActions";
import APIHelper from "../../base/APIHelper";

const RESOURCE = "awards";
const MERCHANT = process.env.REACT_APP_MERCHANT;

function getList(token) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/api/${RESOURCE}?mid=${MERCHANT}`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );
      dispatch({
        type: AwardActionTypes.GET_LIST,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

const AwardActions = {
  ...BaseActions(RESOURCE, AwardActionTypes),
  getList,
};
export default AwardActions;
