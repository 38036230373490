import WilRegisterActionTypes from "./actionTypes";
import BaseActions, { APIRequestGenerator } from "../../base/BaseActions";
import APIHelper from "../../base/APIHelper";

const RESOURCE = "wil";

function getWilInfo(invoiceId, timestamp) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/api/${RESOURCE}/registerStatus/${invoiceId}?timestamp=${timestamp}`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );
      dispatch({
        type: WilRegisterActionTypes.GET_WIL_INFO,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function updateWilRegisterStatus(userId, isShowWilRegister) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/api/${RESOURCE}/registerStatus/${userId}`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "PUT",
        url.toString(),
        {
          isShowWilRegister,
        },
        true,
        headers
      );
      dispatch({
        type: WilRegisterActionTypes.UPDATE_WIL_REGISTER_STATUS,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function checkUserRegisterd(phone) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_WP_API_DOMAIN}/wilRegistration/checkRegistered`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        {
          phone,
        },
        true,
        headers
      );
      dispatch({
        type: WilRegisterActionTypes.CHECK_USER_REGISTERED,
      });
      const { registered } = response || {};
      return Promise.resolve(registered);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

const WilRegisterActions = {
  ...BaseActions(RESOURCE, WilRegisterActionTypes),
  getWilInfo,
  updateWilRegisterStatus,
  checkUserRegisterd,
};
export default WilRegisterActions;
