import BaseActionTypes from "../../base/BaseActionTypes";

const RESOURCE = "Payment";

const PaymentActionTypes = {
  // ...BaseActionTypes(RESOURCE),
  AUTH_PAYER: `${RESOURCE}/AUTH_PAYER`,
};

export default PaymentActionTypes;
