import React from "react";
import { withRouter } from "react-router-dom";
import Search from "../../../components/Search/Search";

const NavbarSearch = ({ isListPage }) => (
  <div
    style={{
      paddingLeft: isListPage ? 0 : 15,
      paddingRight: isListPage ? 0 : 15,
      background: "#fff",
      display: "flex",
      justifyContent: "center",
    }}
  >
    <Search isListPage />
  </div>
);

export default withRouter(NavbarSearch);
