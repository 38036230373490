import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import theme from "themes/default.theme";
import GlobalStyles from "assets/style/Global.style";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import Routes from "./router";
import i18n from "./i18n";
import configureStore from "./redux/store";
import "./index.css";

const store = configureStore();

export default function ScrollToTop() {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) element.scrollIntoView();
      }, 1500);
      return;
    }

    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const App = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <BrowserRouter>
          <ScrollToTop />
          <I18nextProvider i18n={i18n}>
            <Routes />
          </I18nextProvider>
        </BrowserRouter>
      </>
    </ThemeProvider>
  </Provider>
);

ReactDOM.render(<App />, document.getElementById("root"));
