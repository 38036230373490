import BaseActionTypes from "../../base/BaseActionTypes";

const RESOURCE = "Auth";

const AuthActionTypes = {
  ...BaseActionTypes(RESOURCE),
  LOGIN: `${RESOURCE}/LOGIN`,
  REGISTER: `${RESOURCE}/REGISTER`,
  CHECK_ACCOUNT: `${RESOURCE}/CHECK_ACCOUNT`,
  FORGET_PASSWORD: `${RESOURCE}/FORGET_PASSWORD`,
  UPDATE_PASSWORD: `${RESOURCE}/UPDATE_PASSWORD`,
  SEND_SMS: `${RESOURCE}/SEND_SMS`,
  VALIDATE_EMAIL: `${RESOURCE}/VALIDATE_EMAIL`,
  CHECK_SMS_TOKEN: `${RESOURCE}/CHECK_SMS_TOKEN`,
};

export default AuthActionTypes;
