import BaseActionTypes from "../../base/BaseActionTypes";

const RESOURCE = "OCR";

const OCRActionTypes = {
  ...BaseActionTypes(RESOURCE),
  UPDATE: `${RESOURCE}/UPDATE`,
  RESET: `${RESOURCE}/RESET`,
  VALIDATE: `${RESOURCE}/VALIDATE`,
  CHECK_STATUS: `${RESOURCE}/CHECK_STATUS`,
  CREATE_BOOKING: `${RESOURCE}/CREATE_BOOKING`,
  GET_PENDING_BOOKING: `${RESOURCE}/GET_PENDING_BOOKING`,
  UPDATE_RECORD: `${RESOURCE}/UPDATE_RECORD`,
};

export default OCRActionTypes;
