import React from "react";
import { NavLink } from "react-router-dom";

import {
  ABOUT_US,
  CAR_PARK_LIST,
  HOME_PAGE,
  NEWS,
  SERVICE,
  AWARDS,
  HOW_IT_WORKS,
} from "settings/constant";
import { useTranslation } from "react-i18next";

const MainMenu = ({
  showCarpark,
  showService,
  showNews,
  showAbout,
  showAwards,
  showFAQ,
  showHOW,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {showCarpark && (
        <NavLink to={`${CAR_PARK_LIST}`}>{t("navbar.listing")}</NavLink>
      )}
      {showService && (
        <NavLink exact to={`${SERVICE}`}>
          {t("navbar.service")}
        </NavLink>
      )}
      {showNews && (
        <NavLink exact to={`${NEWS}`}>
          {t("navbar.news")}
        </NavLink>
      )}
      {showAbout && (
        <NavLink exact to={`${ABOUT_US}`}>
          {t("navbar.about")}
        </NavLink>
      )}
      {showAwards && (
        <NavLink exact to={`${AWARDS}`}>
          {t("navbar.awards")}
        </NavLink>
      )}
      {showFAQ && (
        <NavLink exact to={`${HOW_IT_WORKS}`}>
          {t("navbar.faq")}
        </NavLink>
      )}
      {showHOW && (
        <NavLink
          exact
          to={{
            pathname: HOME_PAGE,
            state: { scrollTo: "how" },
          }}
        >
          {t("navbar.how")}
        </NavLink>
      )}
    </>
  );
};

export default MainMenu;
