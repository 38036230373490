import BaseActionTypes from "../../base/BaseActionTypes";

const RESOURCE = "Wil";

const WilRegisterActionTypes = {
  ...BaseActionTypes(RESOURCE),
  GET_WIL_INFO: `${RESOURCE}/GET_WIL_INFO`,
  UPDATE_WIL_REGISTER_STATUS: `${RESOURCE}/UPDATE_WIL_REGISTER_STATUS`,
  CHECK_USER_REGISTERED: `${RESOURCE}/CHECK_USER_REGISTERED`,
};

export default WilRegisterActionTypes;
