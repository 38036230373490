import VehiclesActionTypes from "./actionTypes";
import BaseActions, { APIRequestGenerator } from "../../base/BaseActions";
import APIHelper from "../../base/APIHelper";

const RESOURCE = "vehicles";
const MERCHANT = process.env.REACT_APP_MERCHANT;

function getList() {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/api/${RESOURCE}/${MERCHANT}`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );
      dispatch({
        type: VehiclesActionTypes.GET_LIST,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

const VehiclesActions = {
  ...BaseActions(RESOURCE, VehiclesActionTypes),
  getList,
};
export default VehiclesActions;
