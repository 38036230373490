import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Navbar from "components/Navbar/Navbar";
import { LayoutContext } from "context/LayoutProvider";
import useWindowSize from "library/hooks/useWindowSize";
import { ACCOUNT_SETTINGS, CAR_PARK_LIST, HOME_PAGE } from "settings/constant";
import Cookies from "universal-cookie";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import AnimateHeight from "react-animate-height";
import Sticky from "react-stickynode";
import useResizeObserver from "use-resize-observer";
import AuthMenu from "./AuthMenu";
import MainMenu from "./MainMenu";
import MobileMenu from "./MobileMenu";
import ProfileMenu from "./ProfileMenu";
import NavbarSearch from "./NavbarSearch";
import HeaderWrapper, { LogoArea, MobileNavbar } from "./Header.style";
import DEFAULT_AVATAR from "../../../assets/images/profile/default-avatar.png";
import Logo from "../../../components/UI/Logo/Logo";
import HamburgerButtonImg from "../../../assets/images/hamburgButton.svg";
import actions, { useActions } from "../../../redux/actions";

const cookies = new Cookies();

export default withRouter(({ location }) => {
  const { t } = useTranslation();
  const [{ searchVisibility }] = useContext(LayoutContext);
  const token = cookies.get("token");
  const { width } = useWindowSize();
  const { logo, favicon } = useSelector((state) => state.merchant);
  const { avatar, firstName, lastName } = useSelector((state) => state.users);
  const {
    landing: {
      footer: {
        facebook = false,
        google = false,
        wechat = false,
        phone: showPhone = false,
        email: showEmail = false,
        awards = false,
      } = {},
      register: { displaymain: showRegister } = {},
      footercontent: { phone = "", email = "" } = {},
      search: { displaymain: showCarpark } = {},
      services: { displaynav: showService } = {},
      news: { displaynav: showNews } = {},
      about: { displaynav: showAbout } = {},
      awards: { displaynav: showNavAwards, displaymain: showMainAwards } = {},
      faq: { displaynav: showNavFAQ } = {},
      how: { displaynav: showNavHOW } = {},
    } = {},
  } = useSelector((state) => state.site);
  const { setHeaderHeight } = useActions(actions.AppActions);
  const [height, setHeight] = useState(0);
  const isListPage = location.pathname.includes(CAR_PARK_LIST);

  const { ref, height: headerHeight } = useResizeObserver();

  useEffect(() => {
    setHeaderHeight(headerHeight);
  }, [width, headerHeight]);

  return (
    <HeaderWrapper ref={ref}>
      <Sticky enabled={isListPage} innerZ={10001} activeClass="isHeaderSticky">
        {width > 1300 ? (
          <Navbar
            logo={<Logo logo={logo} />}
            navMenu={
              <MainMenu
                showCarpark={showCarpark}
                showService={showService}
                showNews={showNews}
                showAbout={showAbout}
                showAwards={showNavAwards}
                showFAQ={showNavFAQ}
                showHOW={showNavHOW}
              />
            }
            authMenu={<AuthMenu showRegister={showRegister} />}
            isLogin={token}
            avatar={<img src={avatar || DEFAULT_AVATAR} />}
            profileMenu={
              <ProfileMenu
                name={`${firstName} ${lastName}`}
                avatar={<img src={avatar || DEFAULT_AVATAR} />}
              />
            }
            // searchComponent={
            //   location.pathname === CAR_PARK_LIST && <NavbarSearch isListPage />
            // }
            location={location}
            searchVisibility={searchVisibility}
            className={!searchVisibility ? "is_show_transition" : ""}
          />
        ) : (
          <>
            <MobileNavbar>
              <LogoArea href="/">
                <Logo
                  logo={logo}
                  // width={location.pathname === CAR_PARK_LIST ? 30 : 190}
                  // maxWidth={location.pathname === CAR_PARK_LIST ? 30 : 190}
                />
              </LogoArea>

              <button
                className="hamburg-btn"
                // onClick={sidebarHandler}
                onClick={() => setHeight(height === 0 ? "auto" : 0)}
                type="button"
              >
                <span
                  style={{ backgroundImage: `url(${HamburgerButtonImg})` }}
                />
              </button>
            </MobileNavbar>

            <AnimateHeight id="example-panel" duration={500} height={height}>
              <MobileMenu
                className="main-menu"
                showCarpark={showCarpark}
                showService={showService}
                showNews={showNews}
                showAbout={showAbout}
                showRegister={showRegister}
                showAwards={showNavAwards}
                showFAQ={showNavFAQ}
                showHow={showNavHOW}
                avatar={avatar}
                lastName={lastName}
                firstName={firstName}
                setHeight={setHeight}
                onClose={() => setHeight(0)}
              />
            </AnimateHeight>

            {/* {location.pathname === CAR_PARK_LIST && <NavbarSearch />} */}
          </>
        )}
        {location.pathname === CAR_PARK_LIST && <NavbarSearch isListPage />}
      </Sticky>
    </HeaderWrapper>
  );
});
