import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { FacebookFilled } from "@ant-design/icons";
import useWindowSize from "library/hooks/useWindowSize";
import useResizeObserver from "use-resize-observer";
import {
  CompanyDescriptionRow,
  Wrapper,
  CopyRightRow,
  FooterWrapper,
  FooterInner,
  FooterColumn,
  Col,
  Row,
  Footertitle,
  FooterText,
  FooterDivier,
  VersionRow,
  ProwerByRow,
  FooterBottom,
  News,
} from "./Footer.style";
import Heading from "../../../components/UI/Heading/Heading";
import { getMultiLangProperty } from "../../../library/helpers/generalHelpers";
import {
  ABOUT_US,
  AWARDS,
  CANCELLATION,
  CAR_PARK_LIST,
  DISCLAIMER,
  HOW_IT_WORKS,
  LETSPARK_TAC,
  LOGIN,
  NEWS,
  PRIVACY_POLICY,
  SERVICE,
  TAC,
} from "../../../settings/constant";
import actions, { useActions } from "../../../redux/actions";

const cookies = new Cookies();

const Footer = ({ resized = null }) => {
  const { t, i18n } = useTranslation();
  const token = cookies.get("token");
  const NewsActions = useActions(actions.NewsActions);
  const { setFooterHeight } = useActions(actions.AppActions);
  const merchant = useSelector((state) => state.merchant);
  const { width } = useWindowSize();
  const {
    landing: {
      search: { displaymain: showCarpark } = {},
      services: { displaynav: showService } = {},
      news: { displaynav: showNews } = {},
      about: { displaynav: showAbout } = {},
      faq: { displaymain: showFAQ } = {},
      footer: {
        address: showAddress,
        email: showEmail,
        fax: showFax,
        phone: showPhone,
        cancel: showCancellation,
        companyname: showCompanyName,
        contact: showContact,
        disclaimer: showDisclaimer,
        helpful: showHelpfulLink,
        pp: showPrivacy,
        psla: showLPTAC,
        tac: showTAC,
        facebook: showFacebook,
        awards: showAwards,
      } = {},
      footercontent = {},
    } = {},
  } = useSelector((state) => state.site);
  const [news, setNews] = useState([]);

  const { ref, height: footerHeight } = useResizeObserver();

  useEffect(() => {
    setFooterHeight(footerHeight + 110);
  }, [footerHeight, width]);

  useEffect(() => {
    NewsActions.getList()
      .then((response) => {
        if (Array.isArray(response)) {
          const filteredResult = response
            .filter((n) => n.status === true || n.status === "true")
            .sort(
              (a, b) =>
                moment(a.date).format("YYYY-MM-DD") -
                moment(b.date).format("YYYY-MM-DD")
            );
          const finalResult =
            filteredResult.length > 3
              ? filteredResult.slice(0, 3)
              : filteredResult;
          setNews(finalResult);
        } else {
          setNews([]);
        }
      })
      .catch(() => {
        setNews([]);
      });
  }, []);

  const _renderCompanyDescription = () => (
    <>
      {showCompanyName && (
        <CompanyDescriptionRow>
          <div className="merchantName">
            <h1>{getMultiLangProperty(merchant, "name", i18n.language)}</h1>
          </div>
        </CompanyDescriptionRow>
      )}
      <CompanyDescriptionRow>
        <div className="merchantDesc">
          <p>{getMultiLangProperty(merchant, "desc", i18n.language)}</p>
        </div>
        <div className="space" />
      </CompanyDescriptionRow>
    </>
  );

  const _renderHelpfulLinks = () => (
    <>
      <Row>
        <Col>
          <Footertitle style={{ marginBottom: -1 }}>
            {t("footer.helpful")}
          </Footertitle>
        </Col>
      </Row>
      <Row style={{ marginBottom: 20 }}>
        <div className="helpfulLinkCol">
          {!token && (
            <div>
              <Link to={LOGIN}>
                {"> "}
                {t("signIn")}
              </Link>
            </div>
          )}
          {showCarpark && (
            <div>
              <Link to={CAR_PARK_LIST}>
                {"> "}
                {t("footer.carpark")}
              </Link>
            </div>
          )}
          {showService && (
            <div>
              <Link to={SERVICE}>
                {"> "}
                {t("footer.service")}
              </Link>
            </div>
          )}
          {showNews && (
            <div>
              <Link to={NEWS}>
                {"> "}
                {t("footer.latest")}
              </Link>
            </div>
          )}
          {showAbout && (
            <div>
              <Link to={ABOUT_US}>
                {"> "}
                {t("footer.about")}
              </Link>
            </div>
          )}
          {showFAQ && (
            <div>
              <Link to={HOW_IT_WORKS}>
                {"> "}
                {t("footer.faq")}
              </Link>
            </div>
          )}
        </div>
        <div className="helpfulLinkCol">
          {showLPTAC && (
            <div>
              <Link to={LETSPARK_TAC}>
                {"> "}
                {t("footer.lpTac")}
              </Link>
            </div>
          )}
          {showTAC && (
            <div>
              <Link to={TAC}>
                {"> "}
                {t("footer.tac")}
              </Link>
            </div>
          )}
          {showPrivacy && (
            <div>
              <Link to={PRIVACY_POLICY}>
                {"> "}
                {t("footer.privacy")}
              </Link>
            </div>
          )}
          {showDisclaimer && (
            <div>
              <Link to={DISCLAIMER}>
                {"> "}
                {t("footer.disclaimer")}
              </Link>
            </div>
          )}
          {showCancellation && (
            <div>
              <Link to={CANCELLATION}>
                {"> "}
                {t("footer.cancellation")}
              </Link>
            </div>
          )}
          {showAwards && (
            <div>
              <Link to={AWARDS}>
                {"> "}
                {t("footer.awards")}
              </Link>
            </div>
          )}
        </div>
      </Row>
    </>
  );

  const _renderNews = () => (
    <News>
      <Heading as="h4" content={t("footer.news")} />
      {Array.isArray(news) &&
        news.map((n, index) => (
          <div className="news-card" key={index}>
            <div>
              <p>{moment(n.date).format("DD/MM/YYYY")}</p>
              <h3>
                <Link to={`${NEWS}/${n._id}`}>
                  <span>{getMultiLangProperty(n, "topic", i18n.language)}</span>
                </Link>
              </h3>
            </div>
          </div>
        ))}
    </News>
  );

  const _renderContact = () => (
    <>
      <Row>
        <Col>
          <Footertitle>{t("footer.contact")}</Footertitle>
        </Col>
      </Row>
      <Row>
        <div className="NewsCol">
          {showAddress && (
            <FooterText style={{ marginBottom: 3 }}>
              {`${t("footer.address")}: ${getMultiLangProperty(
                footercontent,
                "address",
                i18n.language
              )}`}
            </FooterText>
          )}

          {showPhone && (
            <Row>
              <a href={`tel:${footercontent.phone}`}>
                {`${t("footer.phone")}: `}
                {footercontent.phone}
              </a>
            </Row>
          )}

          {showFax && (
            <FooterText style={{ marginBottom: 3, marginLeft: -1 }}>
              {`${t("footer.fax")}: ${footercontent.fax}`}
            </FooterText>
          )}

          {showEmail && (
            <Row>
              <a className="email" href={`mailto:${footercontent.email}`}>
                {`${t("footer.email")}: `}
                {footercontent.email}
              </a>
            </Row>
          )}
          {showFacebook && (
            <div>
              <a
                href="javascript:window.open('http://www.facebook.com/sharer.php?u='+encodeURIComponent(document.location.href)+'&t='+encodeURIComponent(document.title),'_blank','toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350');void(0)"
                aria-label="facebook"
              >
                <FacebookFilled />
              </a>
            </div>
          )}
        </div>

        <div className="NewsSpace" />
      </Row>
      <Row style={{ marginBottom: 30, marginLeft: -25 }} />
    </>
  );

  const _renderDefaultSizeFooter = () => (
    <FooterInner>
      <FooterColumn style={{ marginBottom: 20 }}>
        {_renderCompanyDescription()}
      </FooterColumn>
      {showHelpfulLink && <FooterColumn>{_renderHelpfulLinks()}</FooterColumn>}
      {showContact && <FooterColumn>{_renderContact()}</FooterColumn>}
      {/* {showNews && <FooterColumn>{_renderNews()}</FooterColumn>} */}
    </FooterInner>
  );

  const { showMap } = useSelector((state) => state.carparks);
  const isListingPage = window.location.pathname === CAR_PARK_LIST;

  return (
    <FooterWrapper
      showMap={showMap && isListingPage}
      resized={resized}
      ref={ref}
    >
      <Wrapper>{_renderDefaultSizeFooter()}</Wrapper>
      <FooterDivier>
        <div>
          <div />
        </div>
      </FooterDivier>
      <Wrapper>
        <Row style={{ alignItems: "center" }}>
          <ProwerByRow>
            <Row>
              <Col style={{ textAlign: "left" }}>
                <h1 className="poweredBy">{t("home.searchArea.poweredBy")}</h1>
              </Col>
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <Col style={{ textAlign: "left" }}>
                <a
                  className="poweredByLogo"
                  href={`https://www.letspark.com.hk/${
                    i18n.language === "en" ? "en-US" : "zh-HK"
                  }/contact`}
                >
                  <img
                    src={require("../../../assets/images/letspark-logo.png")}
                    alt=""
                  />
                </a>
              </Col>
            </Row>
          </ProwerByRow>
          <CopyRightRow>
            <FooterText style={{ marginBottom: 4 }}>
              {t("footer.reserved", {
                year: moment().year(),
                name: getMultiLangProperty(merchant, "name", i18n.language),
              })}
            </FooterText>
          </CopyRightRow>
          <VersionRow>
            <FooterText style={{ marginBottom: 4 }}>
              {`${t("footer.version")}: 20231215.01`}
            </FooterText>
          </VersionRow>
        </Row>
      </Wrapper>
      <FooterBottom />
    </FooterWrapper>
  );
};

export default Footer;
