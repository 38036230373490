// General
export const HOME_PAGE = "/";

// Listing
export const CAR_PARK_LIST = "/carpark";
export const CAR_PARK_DETAIL = "/carparkdetail";

// User
export const PROFILE = "/profile";
export const INVOICE = "/invoice";
export const ACCOUNT_SETTINGS = "/account-settings";
export const IMAGE_EDIT_PAGE = "/edit-image";
export const VEHICLE_EDIT_PAGE = "/edit-vehicle";

// Payment
export const PAYMENT = "/payment";
export const PAYMENT_SUCCESS = "/paymentsuccess";
export const PAYMENT_PROCESSING = "/paymentunder";
export const PAYMENT_EXPIRED = "/paymentexpired";
export const PAYMENT_ERROR = "/cancelalert";
export const PAYMENT_PENDING_SMS = "/paymentpendingsms";
export const PAYMENT_OFFLINE_711 = "/paymentoffline";
export const PAYMENT_OFFLINE_FPS = "/paymentofflinefps";
export const PAYMENT_OFFLINE_OK = "/paymentofflineok";
export const PAYMENT_OFFLINE_CQM = "/paymentofflinecqm";
export const ONE_TIME_PAYMENT = "/otp";

// Others
export const HOW_IT_WORKS = "/faq";
export const ABOUT_US = "/about";
export const LETSPARK_TAC = "/letsparktac";
export const TAC = "/tac";
export const CANCELLATION = "/cancellation";
export const DISCLAIMER = "/disclaimer";
export const PRIVACY_POLICY = "/privacy";
export const OWNER_AGREEMENT = "/ownera";
export const PARKER_AGREEMENT = "/parkera";
export const PARKING_SPACE_LICENCE_AGREEMENT = "/licesea";
export const SERVICE = "/services";
export const NEWS = "/news";
export const MAINTENANCE = "/maintain";
export const OCR_VERIFICATION = "/ocr-verification";
export const RESERVE_SUCCESS = "/reservesuccess";
export const AWARDS = "/awards";

// Login / Registration
export const LOGIN = "/login";
export const MOBILE_LOGIN = "/tellogin";
export const REGISTRATION = "/registration";
export const INDIVIDUAL_FORM = "/indform";
export const REGISTER_INDIVIDUAL_FORM = "/regindform";
export const REGISTER_CORPORATE_FORM = "/regentform";
export const CONFIRM_REGISTRATION = "/congrabox";
export const ACTIVATE_REGISTRATION = "/congraboxforact";
export const CONFIRM_CHANGE_PASSWORD = "/congraboxpw";
export const CORPORATE_CONFIRM_REGISTRATION = "/congraboxforcorp";
export const FORGET_PASSWORD = "/resetpw";
export const RESET_PASSWORD = "/enterpw";
export const CHECK_MAILBOX = "/checkmailbox";
export const ACTIVATE_WITH_MOBILE = "/activate";
export const ACTIVATION_FORM = "/activateform";
export const UPGRADE_ACCOUNT = "/upgradeaccount";
export const MERGE_TNC = "/mergetnc";
export const LOGIN_AGGREGATOR_ACCOUNT = "/loginaggregator";
export const MERGE_ACCOUNT = "/mergeaccount";
export const MERGE_SUCCESS = "/mergesuccess";
