import ParkingActionTypes from "./actionTypes";
import BaseActions, { APIRequestGenerator } from "../../base/BaseActions";
import APIHelper from "../../base/APIHelper";

const RESOURCE = "spaces";
const MERCHANT = process.env.REACT_APP_MERCHANT;

function getFilterData(filterType, query) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/api/${filterType}/${MERCHANT}`,
      query,
      null
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );
      dispatch({
        type: ParkingActionTypes.GET_FILTER_DATA,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function getSpace(params, token = null, signal) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/api/${RESOURCE}/v2/${MERCHANT}`,
      params,
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers,
        signal
      );
      dispatch({
        type: ParkingActionTypes.GET_LIST,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

const ParkingActions = {
  ...BaseActions(RESOURCE, ParkingActionTypes),
  getFilterData,
  getSpace,
};
export default ParkingActions;
