import React, { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import actions, { useActions } from "../redux/actions";
import ErrorHandler from "./ErrorHandler";

function useBaseViewModel() {
  const MerchantActions = useActions(actions.MerchantActions);
  const location = useLocation();

  const [maintenanceDomain, setMaintenanceDomain] = useState();

  const fetchMerchant = () => {
    MerchantActions.getOne()
      .then((response) => {
        const { maintenance_domain = "" } = response;
        setMaintenanceDomain(maintenance_domain);
        if (maintenance_domain) {
          if (
            window.location.href.includes(maintenance_domain) &&
            !window.location.pathname.includes("/maintain")
          ) {
            window.location.href = "/maintain";
          }
        }
      })
      .catch(ErrorHandler);
  };

  useEffect(() => {
    fetchMerchant();
  }, [location.pathname]);

  return { maintenanceDomain };
}

const BaseViewModel = function (model) {
  const Context = createContext(null);
  const withProvider = (ViewComponent) => (props) => {
    const value = model(props);
    const baseValue = useBaseViewModel.bind(ViewComponent)();

    return (
      <Context.Provider value={Object.assign(baseValue, value)}>
        <ViewComponent {...props} />
      </Context.Provider>
    );
  };
  return {
    Context,
    withProvider,
  };
};

export default BaseViewModel;
