import Immutable from 'seamless-immutable';
import CarparksActionTypes from './actionTypes';

const initialState = Immutable({});

const CarparksReducer = (state = initialState, action) => {
  switch (action.type) {
    case CarparksActionTypes.TOGGLE_SHOW_MAP:
      return { ...state, showMap: action.payload };
    default:
      return state;
  }
};

export default CarparksReducer;
