import AggregatorActionTypes from "./actionTypes";
import BaseActions, { APIRequestGenerator } from "../../base/BaseActions";
import APIHelper from "../../base/APIHelper";

const RESOURCE = "aggregator";
const MERCHANT = process.env.REACT_APP_MERCHANT;

function checkUser(phone) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_AGGREGATOR_HOST}/api/sso/checkUser?checkField=${phone}`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );
      dispatch({
        type: AggregatorActionTypes.CHECK_USER,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

const AggregatorActions = {
  ...BaseActions(RESOURCE, AggregatorActionTypes),
  checkUser,
};
export default AggregatorActions;
