import AppActionTypes from "./actionTypes";
import BaseActions, { APIRequestGenerator } from "../../base/BaseActions";
import APIHelper from "../../base/APIHelper";

const RESOURCE = "App";

function setHeaderHeight(height) {
  return {
    type: AppActionTypes.SET_HEADERHEIGHT,
    data: height,
  };
}

function setFooterHeight(height) {
  return {
    type: AppActionTypes.SET_FOOTERHEIGHT,
    data: height,
  };
}

const AppActions = {
  ...BaseActions(RESOURCE, AppActionTypes),
  setHeaderHeight,
  setFooterHeight,
};
export default AppActions;
