import Immutable from "seamless-immutable";
import UsersActionTypes from "./actionTypes";

const initialState = Immutable({
  avatar: "",
  firstName: "",
  lastName: "",
  isMigratedToTPM: false,
});

const UsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case UsersActionTypes.GET_ME:
      return { ...state, ...action.data };
    default:
      return state;
  }
};

export default UsersReducer;
