import BaseActionTypes from "../../base/BaseActionTypes";

const RESOURCE = "Invoices";

const InvoicesActionTypes = {
  ...BaseActionTypes(RESOURCE),
  PAY_INVOICE: `${RESOURCE}/PAY_INVOICE`,
  SEND_PAYMENT_LINK: `${RESOURCE}/SEND_PAYMENT_LINK`,
  GET_RECEIPT: `${RESOURCE}/GET_RECEIPT`,
  CREATE_INVOICE: `${RESOURCE}/CREATE_INVOICE`,
};

export default InvoicesActionTypes;
