import OCRActionTypes from "./actionTypes";
import BaseActions, { APIRequestGenerator } from "../../base/BaseActions";
import APIHelper from "../../base/APIHelper";

const RESOURCE = "residentOCR";
const MERCHANT = process.env.REACT_APP_MERCHANT;

function update(data) {
  return {
    type: OCRActionTypes.UPDATE,
    data,
  };
}

function reset() {
  return {
    type: OCRActionTypes.RESET,
  };
}

function validate(data, token) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/api/${RESOURCE}/ocrValidate`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );
      dispatch({
        type: OCRActionTypes.VALIDATE,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function checkStatus(id, token) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/api/${RESOURCE}/check/${id}`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );
      dispatch({
        type: OCRActionTypes.CHECK_STATUS,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function createBooking(id, data, token) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/api/${RESOURCE}/booking/${id}`,
      { mid: MERCHANT },
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );
      dispatch({
        type: OCRActionTypes.CREATE_BOOKING,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function getPendingBooking(id, token) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/api/${RESOURCE}/bookings/${id}`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );
      dispatch({
        type: OCRActionTypes.GET_PENDING_BOOKING,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function updateRecord(id, data, token) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/api/${RESOURCE}/update/${id}`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );
      dispatch({
        type: OCRActionTypes.UPDATE_RECORD,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

const OCRActions = {
  ...BaseActions(RESOURCE, OCRActionTypes),
  update,
  reset,
  validate,
  checkStatus,
  createBooking,
  getPendingBooking,
  updateRecord,
};
export default OCRActions;
