import BaseActionTypes from "../../base/BaseActionTypes";

const RESOURCE = "Dates";

const DatesActionTypes = {
  ...BaseActionTypes(RESOURCE),
  GET_LIST: `${RESOURCE}/GET_LIST`,
};

export default DatesActionTypes;
