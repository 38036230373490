import Immutable from "seamless-immutable";
import AppActionTypes from "./actionTypes";

const initialState = Immutable({
  headerHeight: 0,
  footerHeight: 0,
});

const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case AppActionTypes.SET_FOOTERHEIGHT:
      return { ...state, footerHeight: action.data };
    case AppActionTypes.SET_HEADERHEIGHT:
      return { ...state, headerHeight: action.data };
    default:
      return state;
  }
};

export default AppReducer;
