import AuthActionTypes from "./actionTypes";
import BaseActions, { APIRequestGenerator } from "../../base/BaseActions";
import APIHelper from "../../base/APIHelper";

const RESOURCE = "auth";
const MERCHANT = process.env.REACT_APP_MERCHANT;

function login(data) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/api/${RESOURCE}/login?mid=${MERCHANT}`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );
      dispatch({
        type: AuthActionTypes.LOGIN,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function register(data) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/api/users?mid=${MERCHANT}`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );
      dispatch({
        type: AuthActionTypes.REGISTER,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function checkAccount(data) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/api/${RESOURCE}/check/account?mid=${MERCHANT}`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );
      dispatch({
        type: AuthActionTypes.CHECK_ACCOUNT,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function forgetPassword(email) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/api/${RESOURCE}/forgetpassword?mid=${MERCHANT}`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        { email },
        true,
        headers
      );
      dispatch({
        type: AuthActionTypes.FORGET_PASSWORD,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function updatePassword(id, data) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/api/${RESOURCE}/pwupdate/${id}`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        { ...data, mid: MERCHANT },
        true,
        headers
      );
      dispatch({
        type: AuthActionTypes.UPDATE_PASSWORD,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function sendSMS(phone) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/api/${RESOURCE}/smslite`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        { ...phone, mid: MERCHANT },
        true,
        headers
      );
      dispatch({
        type: AuthActionTypes.SEND_SMS,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function checkSMSToken(phone, token) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/api/${RESOURCE}/check/token`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        { phone, token },
        true,
        headers
      );
      dispatch({
        type: AuthActionTypes.CHECK_SMS_TOKEN,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function validateEmail(id, token) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/api/${RESOURCE}/emailvalid/${id}`,
      { mid: MERCHANT, token },
      null
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        {},
        true,
        headers
      );
      dispatch({
        type: AuthActionTypes.VALIDATE_EMAIL,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

const AuthActions = {
  ...BaseActions(RESOURCE, AuthActionTypes),
  login,
  register,
  checkAccount,
  forgetPassword,
  updatePassword,
  sendSMS,
  checkSMSToken,
  validateEmail,
};
export default AuthActions;
