import BaseActionTypes from "../../base/BaseActionTypes";

const RESOURCE = "Award";

const AwardActionTypes = {
  ...BaseActionTypes(RESOURCE),
  GET_LIST: `${RESOURCE}/GET_LIST`,
};

export default AwardActionTypes;
