import Immutable from "seamless-immutable";

const initialState = Immutable({
  parkingSpace: [],
});

const ParkingReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default ParkingReducer;
