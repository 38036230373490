import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import {
  ABOUT_US,
  ACCOUNT_SETTINGS,
  CAR_PARK_LIST,
  HOME_PAGE,
  NEWS,
  SERVICE,
  LOGIN,
  REGISTRATION,
  AWARDS,
  HOW_IT_WORKS,
} from "settings/constant";
import Cookies from "universal-cookie";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { FaGlobeAsia } from "react-icons/fa";
import Text from "components/UI/Text/Text";
import TextLink from "components/UI/TextLink/TextLink";
import {
  LangSwitcher,
  MobileMenuContainer,
  AvatarImage,
  AvatarInfo,
  AvatarWrapper,
} from "./Header.style";
import MobileAuthMenu from "./MobileAuthMenu";
import DEFAULT_AVATAR from "../../../assets/images/profile/default-avatar.png";
import Language from "../../../assets/images/language.png";
import LoginLogo from "../../../assets/images/login.png";
import ProfileLogo from "../../../assets/images/register.png";
import LogoutLogo from "../../../assets/images/logout.png";

const cookies = new Cookies();

const MobileMenu = ({
  showCarpark,
  showService,
  showNews,
  showAbout,
  showRegister,
  showAwards,
  showFAQ,
  showHow,
  onClose,
  avatar,
  lastName,
  firstName,
}) => {
  const token = cookies.get("token");
  const history = useHistory();
  const { t, i18n } = useTranslation();

  function handleLogout() {
    cookies.set("token", "");
    history.push("/");
    window.location.reload();
  }

  return (
    <MobileMenuContainer>
      <NavLink exact to={HOME_PAGE} onClick={onClose}>
        {t("navbar.home")}
      </NavLink>

      {showCarpark && (
        <NavLink to={CAR_PARK_LIST} onClick={onClose}>
          {t("navbar.listing")}
        </NavLink>
      )}
      {showService && (
        <NavLink exact to={SERVICE} onClick={onClose}>
          {t("navbar.service")}
        </NavLink>
      )}
      {showNews && (
        <NavLink exact to={NEWS} onClick={onClose}>
          {t("navbar.news")}
        </NavLink>
      )}
      {showAbout && (
        <NavLink exact to={ABOUT_US} onClick={onClose}>
          {t("navbar.about")}
        </NavLink>
      )}
      {showAwards && (
        <NavLink exact to={AWARDS} onClick={onClose}>
          {t("navbar.awards")}
        </NavLink>
      )}
      {showFAQ && (
        <NavLink exact to={HOW_IT_WORKS} onClick={onClose}>
          {t("navbar.faq")}
        </NavLink>
      )}
      {showHow && (
        <NavLink
          exact
          to={{
            pathname: HOME_PAGE,
            state: { scrollTo: "how" },
          }}
          onClick={onClose}
        >
          {t("navbar.how")}
        </NavLink>
      )}

      <div className="avatar-dropdown">
        {token ? (
          <>
            <a onClick={handleLogout} className="logout">
              <img className="logoutLogo" src={LogoutLogo} />
              {t("logout")}
            </a>
            <NavLink className="avatar-a" to={ACCOUNT_SETTINGS}>
              {`${t("hello")} ${lastName} ${firstName}`}
              <img src={avatar || DEFAULT_AVATAR} alt="" />
            </NavLink>
          </>
        ) : (
          <>
            <NavLink className="signin" to={LOGIN}>
              <img src={LoginLogo} />
              {t("signIn")}
            </NavLink>

            {showRegister && (
              <NavLink className="signup" to={REGISTRATION}>
                <img src={ProfileLogo} />
                {t("signUp")}
              </NavLink>
            )}
          </>
        )}
      </div>

      <LangSwitcher
        onClick={() => {
          const lang = i18n.language === "zh" ? "en" : "zh";
          i18next.changeLanguage(lang);
          cookies.set("lang", lang);
          onClose();
        }}
      >
        <img src={Language} alt="" />
        {i18n.language === "zh" ? "EN" : "繁體"}
      </LangSwitcher>
    </MobileMenuContainer>
  );
};

export default MobileMenu;
