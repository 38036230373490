import React, { useContext } from "react";
import { Button } from "antd";
import { FaSearch } from "react-icons/fa";
import SearchViewModel from "./viewModel";
import {
  FormWrapper,
  SearchWrapper,
  SearchButtonCol,
  MonthlyCol,
} from "./Search.style";
import "moment/locale/zh-tw";
import "../../container/CarparkDetail/containers/PackageOptions/PackageOptions.css";
import "../../assets/style/style_overrides.css";
import useRenderSelector from "./useRenderSelector";

const SearchForm = ({ isListPage = false }) => {
  const { t, handleSubmit, bookingTypeValue, onSubmitSearch } = useContext(
    SearchViewModel.Context
  );

  const {
    renderStartDate,
    renderEndDate,
    renderBookingTypeSelector,
    renderRegionSelector,
  } = useRenderSelector({ isListPage });

  return (
    <form
      onSubmit={handleSubmit(onSubmitSearch)}
      style={{ maxWidth: 1920, width: "100%" }}
    >
      <FormWrapper isListPage={isListPage}>
        {renderBookingTypeSelector()}

        {bookingTypeValue !== "Walkin" && renderStartDate()}

        {bookingTypeValue !== "Walkin" && renderEndDate()}

        {isListPage ? (
          <Button
            type="primary"
            htmlType="submit"
            size="small"
            className="isListPageSerachButton"
          >
            <div>
              <FaSearch style={{ marginRight: 5, width: 12 }} />
              {t("home.searchArea.search")}
            </div>
          </Button>
        ) : (
          <>
            {renderRegionSelector()}
            <SearchButtonCol>
              <Button
                type="primary"
                htmlType="submit"
                className="home-page-search-btn"
              >
                <>
                  <FaSearch style={{ marginRight: 5, width: 12, height: 12 }} />
                  {t("home.searchArea.search")}
                </>
              </Button>
            </SearchButtonCol>
          </>
        )}
      </FormWrapper>
    </form>
  );
};

const Search = ({ isListPage = false }) => (
  <>
    {isListPage ? (
      <SearchForm isListPage />
    ) : (
      <SearchWrapper>
        <SearchForm />
      </SearchWrapper>
    )}
  </>
);

export default SearchViewModel.withProvider(Search);
