import BaseActionTypes from "../../base/BaseActionTypes";

const RESOURCE = "Promotion";

const PromotionActionTypes = {
  ...BaseActionTypes(RESOURCE),
  CHECK_DISCOUNT: `${RESOURCE}/CHECK_DISCOUNT`,
};

export default PromotionActionTypes;
