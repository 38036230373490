import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import {
  CarFilled,
  CarryOutFilled,
  ClockCircleFilled,
} from "@ant-design/icons";
import queryString from "query-string";
import { isEmpty } from "lodash";
import { CAR_PARK_LIST } from "../../settings/constant";
import BaseViewModel from "../../base/BaseViewModel";
import actions, { useActions } from "../../redux/actions";
import ErrorHandler from "../../base/ErrorHandler";
import Calendar from "../../assets/images/calendar.png";

function useSearch() {
  const history = useHistory();
  const { location } = history || {};
  const isListPage = location.pathname.includes(CAR_PARK_LIST);

  const { t, i18n } = useTranslation();

  const ParkingActions = useActions(actions.ParkingActions);
  const MerchantActions = useActions(actions.MerchantActions);

  const [regions, setRegions] = useState([]);
  const [bookingTypesOptions, setBookingTypesOptions] = useState([]);

  const defaultBookingTypes = [
    {
      label: t("home.searchArea.carpark"),
      value: "Walkin",
      name_en: "Carpark",
      name_tc: "車場",
    },
    {
      label: t("home.searchArea.monthly"),
      value: "Monthly",
      name_en: "Monthly",
      name_tc: "月租",
    },
    {
      label: t("home.searchArea.hourly"),
      value: "Hourly",
      name_en: "Hourly",
      name_tc: "時租",
    },
  ];

  const [bookingTypes, setBookingTypes] = useState([]);

  const query = queryString.parse(location.search);

  function getBookingType() {
    if (isListPage && query.type) {
      return bookingTypes.find(({ value }) => value === query.type);
    }

    // if (isListPage && !query.type) {
    //   return bookingTypes.find(({ value }) => value === "Walkin");
    // }

    return bookingTypes[0];
  }

  const today = moment().utcOffset(8);

  function roundTime(date, duration, method) {
    return moment(Math[method](+date / +duration) * +duration);
  }

  function getStartDateRoundTime() {
    return roundTime(
      moment().utcOffset(8).add(15, "minutes"),
      moment.duration(15, "minutes"),
      "ceil"
    ).format("YYYY-MM-DD HH:mm");
  }

  function getEndDateRoundTime() {
    return roundTime(
      moment().utcOffset(8).add({ hours: 1, minutes: 15 }),
      moment.duration(15, "minutes"),
      "ceil"
    ).format("YYYY-MM-DD HH:mm");
  }

  function getMonthlyEndDate() {
    const endOfThisMonth = moment().utcOffset(8).endOf("month");

    const endOfNextMonth = moment()
      .utcOffset(8)
      .add(1, "months")
      .endOf("month");

    const quarterMonth = today.clone().date(22);
    const endMonth = today.clone().endOf("month");

    const between22and31 = today.isBetween(
      quarterMonth,
      endMonth,
      undefined,
      "[]"
    );

    if (query.end) {
      return moment(new Date(query.end)).utcOffset(8);
    }
    return between22and31 ? endOfNextMonth : endOfThisMonth;
  }

  function getDate(dateType) {
    const { start: queryStart, end: queryEnd, type } = query || {};

    if (isListPage && type === "Hourly") {
      if (!queryStart && dateType === "start") {
        const startDateRound = getStartDateRoundTime();
        return moment(startDateRound);
      }
      if (!queryEnd && dateType === "end") {
        const endDateRound = getEndDateRoundTime();
        return moment(endDateRound);
      }
    }

    if (isListPage && type === "Monthly") {
      if (!queryStart && dateType === "start") {
        return moment().utcOffset(8);
      }

      if (isListPage && dateType === "end") {
        return getMonthlyEndDate();
      }
    }

    if (isListPage && query && query.start && query.end) {
      return moment(new Date(query[dateType])).utcOffset(8);
    }

    return null;
  }

  const { control, handleSubmit, setValue, register, watch } = useForm();

  const bookingTypeValue = watch("bookingType")?.value || "";
  const startDateValue = watch("start");
  const endDateValue = watch("end");
  const regionValue = watch("region")?.value || "";

  function renderBookingTypeIcon() {
    switch (bookingTypeValue) {
      case "Hourly":
        return <ClockCircleFilled className="booking-type-icon" />;
      case "Walkin":
        return <CarFilled className="booking-type-icon" />;
      default:
        return (
          <img
            src={Calendar}
            style={{ width: 17, height: 17 }}
            className="booking-type-icon"
            alt=""
          />
        );
    }
  }

  // useEffect(() => {
  //   const params = new URLSearchParams();

  //   if (isListPage && bookingTypeValue !== query.type) {
  //     let search = params.toString();

  //     const currentParams = Object.fromEntries(params);

  //     if (query.vehicle) currentParams.vehicle = query.vehicle;
  //     if (query.feature) currentParams.feature = query.feature;
  //     if (query.region) currentParams.region = query.region;

  //     if (query.vehicle || query.feature || query.region) {
  //       search = queryString.stringify(currentParams);
  //     }

  //     history.push({ search });
  //   }
  // }, [bookingTypeValue]);

  function handleChangeBookingType(e) {
    const { value } = e || {};
    const params = new URLSearchParams();

    setValue("bookingType", e);

    if (value === "Monthly" && isListPage) {
      const halfMonthMark = 16;
      const quarterMonthMark = 22;

      const startMonth = today.clone().startOf("month");
      const halfMonth = today.clone().date(halfMonthMark);
      const quarterMonth = today.clone().date(quarterMonthMark);
      const endMonth = today.clone().endOf("month");

      let start;
      let end;

      start = today.format("YYYY-MM-DD");
      end = today.clone().endOf("month").format("YYYY-MM-DD");

      if (today.isBetween(startMonth, halfMonth, undefined, "[]")) {
        end = today.clone().endOf("month").format("YYYY-MM-DD");
      }

      if (today.isBetween(halfMonth, quarterMonth, undefined, "()")) {
        end = today.clone().endOf("month").format("YYYY-MM-DD");
      }

      if (today.isBetween(quarterMonth, endMonth, undefined, "[]")) {
        end = today
          .clone()
          .add(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD");
      }

      setValue("start", moment(start));
      setValue("end", moment(end));

      params.append("start", moment(start).format("YYYY-MM-DD"));
      params.append("end", moment(end).format("YYYY-MM-DD"));
      params.append("type", value);
    }

    if (value === "Hourly" && isListPage) {
      const hourlyStart = getStartDateRoundTime();
      const hourlyEnd = getEndDateRoundTime();

      setValue("start", moment(hourlyStart));
      setValue("end", moment(hourlyEnd));

      params.append("start", moment(hourlyStart).format("YYYY-MM-DDTHH:mm"));
      params.append("end", moment(hourlyEnd).format("YYYY-MM-DDTHH:mm"));
      params.append("type", value);
    }

    const search = params.toString();

    history.push({ search });
  }

  function setDefaultMonthlyStart() {
    const params = new URLSearchParams();

    if (!startDateValue) {
      const monthlyStart = today;
      // const monthlyStart = moment().utcOffset(8);
      setValue("start", moment(monthlyStart));
      params.append("start", moment(monthlyStart).format("YYYY-MM-DD"));
    }
  }

  function setDefaultMonthlyEnd() {
    const params = new URLSearchParams();
    const monthlyEnd = getMonthlyEndDate();
    setValue("end", moment(monthlyEnd));
    params.append("end", moment(monthlyEnd).format("YYYY-MM-DD"));
  }

  function setDefaultHourlyStart() {
    if (!startDateValue) {
      const params = new URLSearchParams();
      const hourlyStart = getStartDateRoundTime();
      setValue("start", moment(hourlyStart));
      params.append("start", moment(hourlyStart).format("YYYY-MM-DDTHH:mm"));
    }
  }

  function setDefaultHourlyEnd() {
    if (!endDateValue) {
      const params = new URLSearchParams();
      const hourlyEnd = getEndDateRoundTime();
      setValue("end", moment(hourlyEnd));
      params.append("end", moment(hourlyEnd).format("YYYY-MM-DDTHH:mm"));
    }
  }

  function onChangeStart(start) {
    setValue("start", start);

    if (bookingTypeValue === "Hourly") {
      const oneHourLater = moment(start).utcOffset(8).add(1, "hours");
      setValue("end", oneHourLater);
    }
  }

  function onSubmitSearch(data) {
    const { start, end } = data;

    if (start && end) {
      if (bookingTypeValue === "Monthly") {
        data.start = start.format("YYYY-MM-DD");
        data.end = end.format("YYYY-MM-DD");
      } else {
        data.start = start.format("YYYY-MM-DD HH:mm").replace(" ", "T");
        data.end = end.format("YYYY-MM-DD HH:mm").replace(" ", "T");
      }
    } else {
      data.start = moment().utcOffset(8).format("YYYY-MM-DD");
      data.end = getMonthlyEndDate().format("YYYY-MM-DD");
    }

    const params = new URLSearchParams();
    params.append("type", bookingTypeValue);

    if (bookingTypeValue !== "Walkin") {
      params.append("start", data.start);
      params.append("end", data.end);
    }

    if (isListPage) {
      let search = params.toString();

      const currentParams = Object.fromEntries(params);

      if (query.vehicle) currentParams.vehicle = query.vehicle;
      if (query.service) currentParams.service = query.service;
      if (query.feature) currentParams.feature = query.feature;
      if (query.region) currentParams.region = query.region;

      if (query.vehicle || query.service || query.feature || query.region) {
        search = queryString.stringify(currentParams);
      }

      history.push({ search });
    } else {
      if (regionValue !== "") {
        const selectedRegion = regions.find(
          ({ value }) => value === regionValue
        );
        params.append("region", selectedRegion.name_en);
      }

      history.push({
        pathname: CAR_PARK_LIST,
        search: params.toString(),
      });
    }
  }

  useEffect(() => {
    register("start");
    register("end");
    register("bookingType");
  }, [register]);

  const defaultRegion = {
    value: "",
    label: t("listing.location"),
    name_en: "Any location",
    name_tc: "任何位置",
  };

  function getRegions() {
    ParkingActions.getFilterData("regions")
      .then((response) => {
        if (Array.isArray(response)) {
          const cleanedResponse = response
            .filter(({ status }) => status === "Publish")
            .map(({ _id, name_en, name_tc }) => {
              const label = i18n.language === "en" ? name_en : name_tc;

              return {
                value: _id,
                label,
                name_en,
                name_tc,
              };
            });
          setRegions([defaultRegion, ...cleanedResponse]);
        } else {
          setRegions([defaultRegion]);
        }
      })
      .catch(() => setRegions([defaultRegion]));
  }

  function disabledEndDate(current) {
    const renewstart = "16";
    const hourlyBuffer = 15;
    const hourlyMaxDays = 10;

    const hourlyMinDate = roundTime(
      moment().utcOffset(8).add(hourlyBuffer, "minutes"),
      moment.duration(15, "minutes"),
      "ceil"
    );
    const hourlyMaxDate = roundTime(
      moment().utcOffset(8).add(hourlyMaxDays, "days"),
      moment.duration(15, "minutes"),
      "floor"
    ).endOf("day");
    const renewStartDate = parseInt(renewstart);
    const endOfMonth = moment().utcOffset(8).endOf("month");
    const endOfNextMonth = moment()
      .utcOffset(8)
      .add(1, "months")
      .endOf("month");

    if (bookingTypeValue === "Hourly") {
      return (
        current < hourlyMinDate ||
        current > hourlyMaxDate ||
        current < moment(startDateValue).startOf("hour")
      );
    }
    if (today.format("D") >= Math.max(renewStartDate, 22)) {
      return (
        current < endOfNextMonth.startOf("day") ||
        current > endOfNextMonth.endOf("day")
      );
    }
    return (
      current < endOfMonth.startOf("day") || current > endOfMonth.endOf("day")
    );
  }

  const disabledStartDate = (current) => {
    if (bookingTypeValue === "Hourly") {
      const tenDaysFromToday = moment()
        .utcOffset(8)
        .add(10, "day")
        .startOf("day");

      const hourlyToday = moment().utcOffset(8).add(0, "day").startOf("day");
      return (
        current.isBefore(hourlyToday, "day") ||
        current.isAfter(tenDaysFromToday, "day")
      );
    }
    const startMonth = today.clone().startOf("month");
    const halfMonth = today.clone().date(16);
    const quarterMonth = today.clone().date(22);
    const endMonth = today.clone().endOf("month");

    const between1And16 = today.isBetween(
      startMonth,
      halfMonth,
      undefined,
      "[]"
    );
    const between16And22 = today.isBetween(
      halfMonth,
      quarterMonth,
      undefined,
      "()"
    );
    const between22and31 = today.isBetween(
      quarterMonth,
      endMonth,
      undefined,
      "[]"
    );

    const startOfNextMonth = moment()
      .utcOffset(8)
      .add(1, "months")
      .startOf("month")
      .startOf("day");

    if (between1And16 || between16And22) {
      return !current.isSame(today, "day");
    }

    if (between22and31) {
      return (
        !current.isSame(today, "day") &&
        !current.isSame(startOfNextMonth, "day")
      );
    }
  };

  const fetchMerchant = () => {
    MerchantActions.getOne()
      .then((response) => {
        const {
          features: {
            booking: { hourly = false, monthly = false, carpark = false },
          },
        } = response || {};

        const filteredBookingTypes = defaultBookingTypes.reduce(
          (output, bookingType) => {
            const { value } = bookingType;
            if (
              (!hourly && value === "Hourly") ||
              (!monthly && value === "Monthly") ||
              (!carpark && value === "Walkin") ||
              (!isListPage && value === "Walkin")
            )
              return output;

            output.push(bookingType);
            return output;
          },
          []
        );

        setBookingTypes(filteredBookingTypes);
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  };

  useEffect(() => {
    fetchMerchant();
    if (!isListPage) {
      getRegions();
    }
  }, []);

  useEffect(() => {
    // if (isListPage) {
    setBookingTypesOptions(bookingTypes);
    // } else {
    //   setBookingTypesOptions(
    //     bookingTypes.filter(({ value }) => value !== "Walkin")
    //   );
    // }

    setValue([
      { bookingType: getBookingType() },
      { start: getDate("start") },
      {
        end: getDate("end"),
      },
      { region: defaultRegion },
    ]);
  }, [bookingTypes]);

  useEffect(() => {
    if (isEmpty(bookingTypes)) return;
    if (!isEmpty(location.search)) return;

    const params = new URLSearchParams();

    const type = getBookingType();

    setValue("bookingType", type);
    setValue("region", defaultRegion);

    if (type.value === "Hourly" && isListPage) {
      const startDateRound = getStartDateRoundTime();
      const endDateRound = getEndDateRoundTime();

      setValue("start", moment(startDateRound));
      setValue("end", moment(endDateRound));
      params.append("start", moment(startDateRound).format("YYYY-MM-DDTHH:mm"));
      params.append("end", moment(endDateRound).format("YYYY-MM-DDTHH:mm"));
      params.append("type", type.value);

      return;
    }

    if (type.value === "Monthly" && isListPage) {
      console.log("asd");

      setValue("start", moment().utcOffset(8));

      setValue("end", getMonthlyEndDate());
      params.append("start", moment().utcOffset(8).format("YYYY-MM-DD"));
      params.append("end", getMonthlyEndDate().format("YYYY-MM-DD"));
      params.append("type", type.value);
    }

    const search = params.toString();

    setTimeout(() => {
      history.push({ search });
    }, 0);
  }, [location.search, bookingTypes]);

  return {
    t,
    i18n,
    bookingTypes: bookingTypesOptions,
    renderBookingTypeIcon,
    regions,
    Controller,
    control,
    handleSubmit,
    setValue,
    bookingTypeValue,
    startDateValue,
    endDateValue,
    regionValue,
    onSubmitSearch,
    setDefaultMonthlyStart,
    setDefaultMonthlyEnd,
    setDefaultHourlyStart,
    setDefaultHourlyEnd,
    onChangeStart,
    disabledStartDate,
    disabledEndDate,
    watch,
    handleChangeBookingType,
  };
}

const SearchViewModel = BaseViewModel(useSearch);

export default SearchViewModel;
