import React, { useContext } from "react";
import { DatePicker } from "antd";
import Select from "components/UI/Select/Select";
import { components } from "react-select";
import en from "antd/es/date-picker/locale/en_GB";
import zh from "antd/es/date-picker/locale/zh_TW";
import { CarFilled, ClockCircleFilled } from "@ant-design/icons";
import SearchViewModel from "./viewModel";
import {
  ComponentWrapper,
  FromToCol,
  ParkingAtCol,
  MonthlyCol,
} from "./Search.style";
import "moment/locale/zh-tw";
import "../../container/CarparkDetail/containers/PackageOptions/PackageOptions.css";
import "../../assets/style/style_overrides.css";
import FormControl from "../UI/FormControl/FormControl";
import Calendar from "../../assets/images/calendar.png";
import HourlyImg from "../../assets/images/hourly.png";

const BookingTypeSelectorStyle = {
  control: (base) => ({
    ...base,
    border: "none",
    boxShadow: "none",
    background: "transparent",
    width: "100%",
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    backgroundColor: isSelected ? "#dedede" : "#fff",
    color: "#16181b",
    "&:hover": { backgroundColor: isSelected ? "#dedede" : "#dedede" },
    "&:active": { backgroundColor: "#dedede" },
    display: "flex",
    alignItems: "center",
  }),
};

const RegionSelectorStyle = {
  control: (base) => ({
    ...base,
    border: "none",
    boxShadow: "none",
    background: "transparent",
    width: "100%",
  }),
};

const Option = ({ children, ...props }) => {
  function renderBookingTypeIcon() {
    switch (props.data.value) {
      case "Hourly":
        return (
          <img
            src={HourlyImg}
            alt=""
            style={{ width: 17, height: 17, alignItems: "center" }}
            className="booking-type-icon"
          />
        );
      case "Walkin":
        return <CarFilled className="booking-type-icon" />;
      default:
        return (
          <img
            src={Calendar}
            style={{ width: 17, height: 17 }}
            className="booking-type-icon"
            alt=""
          />
        );
    }
  }

  return (
    <components.Option {...props}>
      <>
        {renderBookingTypeIcon()}
        {children}
      </>
    </components.Option>
  );
};

const SingleValue = ({ children, ...props }) => {
  function renderBookingTypeIcon() {
    switch (props.data.value) {
      case "Hourly":
        return (
          <img
            src={HourlyImg}
            alt=""
            style={{ width: 17, height: 17 }}
            className="booking-type-icon"
          />
        );
      case "Walkin":
        return <CarFilled className="booking-type-icon" />;
      default:
        return (
          <img
            src={Calendar}
            style={{ width: 17, height: 17 }}
            className="booking-type-icon"
            alt=""
          />
        );
    }
  }
  return (
    <components.SingleValue {...props}>
      <>
        {renderBookingTypeIcon()}
        {children}
      </>
    </components.SingleValue>
  );
};

export default function useRenderSelector({ isListPage }) {
  const {
    t,
    i18n,
    bookingTypes,
    renderBookingTypeIcon,
    regions,
    Controller,
    control,
    setValue,
    bookingTypeValue,
    startDateValue,
    endDateValue,
    regionValue,
    setDefaultMonthlyStart,
    setDefaultMonthlyEnd,
    setDefaultHourlyStart,
    setDefaultHourlyEnd,
    onChangeStart,
    disabledStartDate,
    disabledEndDate,
    handleChangeBookingType,
    watch,
  } = useContext(SearchViewModel.Context);

  const renderStartDate = () => {
    const showTime =
      bookingTypeValue === "Hourly" ? { format: "HH:mm" } : false;
    const format =
      bookingTypeValue === "Monthly" ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm";
    const locale = i18n.language === "en" ? en : zh;
    const style = { border: "1px solid #d9d9d9" };

    const onClickStart =
      bookingTypeValue === "Hourly"
        ? setDefaultHourlyStart
        : setDefaultMonthlyStart;

    return (
      <FromToCol isListPage={isListPage}>
        <ComponentWrapper isListPage={isListPage}>
          <DatePicker
            inputReadOnly
            showTime={showTime}
            format={format}
            locale={locale}
            onClick={onClickStart}
            onChange={onChangeStart}
            style={style}
            value={startDateValue}
            placeholder={t("listing.startDate")}
            suffixIcon={null}
            allowClear={false}
            showToday={false}
            disabledDate={disabledStartDate}
            minuteStep={15}
            onOk={(start) => setValue("start", start)}
          />
        </ComponentWrapper>
      </FromToCol>
    );
  };

  const renderEndDate = () => {
    const showTime =
      bookingTypeValue === "Hourly" ? { format: "HH:mm" } : false;
    const format =
      bookingTypeValue === "Monthly" ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm";
    const locale = i18n.language === "en" ? en : zh;
    const style = { border: "1px solid #d9d9d9" };

    const onClickEnd =
      bookingTypeValue === "Hourly"
        ? setDefaultHourlyEnd
        : setDefaultMonthlyEnd;

    return (
      <FromToCol isListPage={isListPage}>
        <ComponentWrapper isListPage={isListPage}>
          <DatePicker
            inputReadOnly
            showTime={showTime}
            format={format}
            locale={locale}
            onClick={onClickEnd}
            onChange={(end) => setValue("end", end)}
            style={style}
            value={endDateValue}
            placeholder={t("listing.endDate")}
            suffixIcon={null}
            allowClear={false}
            showToday={false}
            disabledDate={disabledEndDate}
            minuteStep={15}
            // onOk={(end) => setValue("end", end)}
          />
        </ComponentWrapper>
      </FromToCol>
    );
  };

  const renderBookingTypeSelector = () => (
    <MonthlyCol isListPage={isListPage}>
      <ComponentWrapper className="booking-type-react-select">
        {/* {renderBookingTypeIcon()} */}
        <div className="bookingType-selector">
          <Select
            defaultValue={bookingTypes[0]}
            options={bookingTypes}
            style={BookingTypeSelectorStyle}
            value={watch("bookingType")}
            components={{ SingleValue, Option }}
            onChange={(e) => handleChangeBookingType(e)}
          />
        </div>
      </ComponentWrapper>
    </MonthlyCol>
  );

  const renderRegionSelector = () => (
    <ParkingAtCol>
      <ComponentWrapper>
        <div className="location-selector">
          <FormControl>
            <Controller
              as={
                <Select
                  options={regions}
                  placeholder={t("listing.location")}
                  style={RegionSelectorStyle}
                  value={regionValue}
                />
              }
              id="region"
              name="region"
              control={control}
            />
          </FormControl>
        </div>
      </ComponentWrapper>
    </ParkingAtCol>
  );

  return {
    renderStartDate,
    renderEndDate,
    renderBookingTypeSelector,
    renderRegionSelector,
  };
}
