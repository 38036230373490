import BookingsActionTypes from "./actionTypes";
import BaseActions, { APIRequestGenerator } from "../../base/BaseActions";
import APIHelper from "../../base/APIHelper";

const RESOURCE = "bookings";
const MERCHANT = process.env.REACT_APP_MERCHANT;

function createBooking(space, data, token) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/api/${RESOURCE}/${space}?mid=${MERCHANT}`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );
      dispatch({
        type: BookingsActionTypes.CREATE_BOOKING,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function handle3DS(id) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/api/${RESOURCE}/3ds/${id}`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );
      dispatch({
        type: BookingsActionTypes.HANDLE_3DS,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function getList(token) {
  return async (dispatch) => {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/api/${RESOURCE}/user/order`,
      { mid: MERCHANT },
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );
      dispatch({
        type: BookingsActionTypes.GET_LIST,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

const BookingsActions = {
  ...BaseActions(RESOURCE, BookingsActionTypes),
  createBooking,
  handle3DS,
  getList,
};
export default BookingsActions;
