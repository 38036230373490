import moment from "moment";

export const getMultiLangProperty = (item, key, lang) => {
  if (typeof item === "string") return item;
  if (!item || typeof item !== "object") return "";
  if (lang === "zh") {
    return (
      item[`${key}_tc`] ||
      item[`${key}_zh`] ||
      item[`${key}Tc`] ||
      item[`${key}Zh`] ||
      ""
    );
  }
  return item[`${key}_en`] || item[key] || item[`${key}En`] || "";
};

export function capitalize(string) {
  if (string !== null && string !== undefined) {
    const capitalized = string.charAt(0).toUpperCase() + string.slice(1);
    return capitalized.toString();
  }
}

export const formatMoney = (value = 0) =>
  (value || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");

export const roundTime = (date, duration, method) =>
  moment(Math[method](+date / +duration) * +duration);

export const truncate = (str, maxLength) =>
  str && str.length > maxLength ? `${str.substring(0, maxLength - 3)}...` : str;
